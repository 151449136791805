import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../common/button'

const SuccessLayout = ({
  heading,
  topText,
  subheading,
  bottomText,
  buttonText,
}) => {
  return (
    <>
      <div
        className={
          'flex flex-col container max-w-[680px] mx-auto text-center h-screen justify-center items-center gap-y-16 md:gap-y-32'
        }
      >
        <div className={'flex flex-col md:gap-y-6'}>
          {heading && (
            <h1 className={'font-medium text-4xl md:text-5xl lg:text-6xl'}>
              {heading}
            </h1>
          )}
          {topText && <p className={'text-lg md:text-xl'}>{topText}</p>}
        </div>
        <div className={'flex flex-col gap-y-4 md:gap-y-3 lg:gap-y-6'}>
          {subheading && (
            <h3 className={'font-semibold text-2xl md:text-3xl mt-2 md:mt-0'}>
              {subheading}
            </h3>
          )}
          {bottomText && <p className={'text-lg md:text-xl'}>{bottomText}</p>}
          {buttonText && (
            <div className={'w-max mx-auto'}>
              <Button variant={'outlined'} path={'/'}>
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SuccessLayout

SuccessLayout.propTypes = {
  heading: PropTypes.string,
  topText: PropTypes.string,
  subheading: PropTypes.string,
  bottomText: PropTypes.string,
}
