import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SuccessLayout from '../components/success-page'
import SEO from '../components/seo'

const SuccessTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const template = page.template.templateSuccess

  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <SuccessLayout
        heading={template.heading}
        topText={template.topTextField}
        subheading={template.subheading}
        bottomText={template.bottomTextField}
        buttonText={template.buttonText}
      />
    </Layout>
  )
}

export default SuccessTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
      template {
        ... on WpSuccessTemplate {
          templateName
          templateSuccess {
            bottomTextField
            buttonText
            heading
            subheading
            topTextField
          }
        }
      }
    }
  }
`
